import { type AxiosResponse } from 'axios';

import { BaseAPIService } from './base.api';

export class CodeTemplateAPI extends BaseAPIService {
  uploadFile(
    id: string,
    data: Blob,
    opts?: {
      contentType?: string;
      filename?: string;
    }
  ): Promise<AxiosResponse> {
    const headers: Record<string, string> = {};
    headers['Content-Type'] = opts?.contentType ?? data.type;
    if (opts?.filename) {
      headers['x-lp-filename'] = opts.filename;
    }

    return this.client.post(`/code-templates/${id}/upload`, data, {
      headers,
    });
  }
}
